import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'
import Fade from 'react-reveal/Fade'

export interface Props {
  description?: string
  id: string
  image?: ImageProps
  languageCode: string
  leftThumb?: ImageProps
  pax?: string
  rightThumb?: ImageProps
  size?: string
  title: string
  URL?: string
  weight?: number
}

export const Room = memo(function Room({
  description,
  id,
  image,
  languageCode,
  leftThumb,
  pax,
  rightThumb,
  size,
  title,
  URL,
}: Props) {
  return (
    <Container id={`room-${id}`}>
      <Fade bottom distance="3.75rem">
        <Wrapper className="room-item" dial={5} row stretch wrap>
          <LeftSide dial={4}>
            {leftThumb || rightThumb ? (
              <LazyLoad>
                <Thumbs row space="between">
                  {leftThumb ? (
                    <Thumb
                      style={{ backgroundImage: `url(${leftThumb.src})` }}
                    />
                  ) : null}
                  {rightThumb ? (
                    <Thumb
                      style={{ backgroundImage: `url(${rightThumb.src})` }}
                    />
                  ) : null}
                </Thumbs>
              </LazyLoad>
            ) : null}
            {title ? <Title>{title}</Title> : null}
            {pax || size ? (
              <Info row>
                {pax ? <Pax>{pax}</Pax> : null}
                {size ? <Size>{size}</Size> : null}
              </Info>
            ) : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {URL ? (
              <Button
                label={useVocabularyData('details', languageCode)}
                URL={URL}
                variant="corners"
              />
            ) : null}
          </LeftSide>
          <RightSide>
            <MainImage>
              {image ? (
                <LazyLoad>
                  <Image {...image} />
                </LazyLoad>
              ) : null}
            </MainImage>
          </RightSide>
        </Wrapper>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  max-width: 77.125rem;
  margin: 9.75rem auto;
  padding: 0 1.5rem;
  &:nth-of-type(even) {
    .room-item {
      > div {
        &:first-of-type {
          order: 2;
          padding-right: 6.25vw;
          padding-left: 9.306vw;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    margin: 7.5rem auto;
    &:nth-of-type(even) {
      .room-item {
        > div {
          &:first-of-type {
            padding-right: 1.5rem;
            padding-left: 3.75rem;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    &:nth-of-type(even) {
      .room-item {
        > div {
          &:first-of-type {
            padding: 0;
          }
        }
      }
    }
  }
`

const Wrapper = styled(FlexBox)``

const LeftSide = styled(FlexBox)`
  width: 50%;
  padding-right: 9.306vw;
  padding-left: 6.25vw;
  .lazyload-wrapper {
    width: 100%;
  }

  @media (max-width: 1023px) {
    padding-right: 3.75rem;
    padding-left: 1.5rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
  }
`

const Thumbs = styled(FlexBox)`
  margin-bottom: 3.75rem;

  @media (max-width: 767px) {
    margin-bottom: 3rem;
  }
`

const Thumb = styled.div`
  width: calc(50% - 0.75rem);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: calc(50% - 0.75rem);
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
`

const Info = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1.125rem;
  margin-bottom: 3rem;
  text-transform: uppercase;
`

const Pax = styled.span``

const Size = styled.span`
  display: flex;
  align-items: center;
  &:before {
    content: '';
    display: block;
    width: 0.375rem;
    height: 6px;
    background: ${({ theme }) => theme.colors.variants.primaryDark1};
    border-radius: 50%;
    margin: 0 0.75rem;
  }
`

const Description = styled.div`
  display: block;
  display: -webkit-box;
  max-height: 5.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

const RightSide = styled.div`
  width: 50%;
`

const MainImage = styled.div`
  padding-bottom: 134%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    display: none;
  }
`
